// assets
import { 
    IconHeart,
    IconHandOff,
    IconDatabaseImport
} from '@tabler/icons';

// constant
export const icons = {
    IconHeart,
    IconHandOff,
    IconDatabaseImport
};

const manageFundersv2 = {
    id: 'manage-funders-v2',
    title: 'side_menu.manage_funders',
    type: 'group',
    roles: ['user', 'subuser', 'member', 'supervisor', 'admin', 'demo', 'sales', 'superadmin', 'it'],
    collapse: false
};

export default manageFundersv2;
