import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
    AppBar,
    Box,
    CssBaseline,
    Toolbar,
    useMediaQuery,
    Alert,
    Snackbar,
    Grow
} from "@mui/material";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";

import SweetAlert from "react-bootstrap-sweetalert";
import { closeToaster, closeMessage, showMessage, setUserToken, showSessionTimeout, showErrorToaster } from "store/Shared.Slice";

// assets
import { IconChevronRight } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { useRestoreScrollYPosition } from "hooks/useRestoreScrollYPosition";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up("md")]: {
                marginLeft: -(drawerWidth - 20),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
                width: `calc(100% - ${drawerWidth}px)`,
                padding: "16px",
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
                width: `calc(100% - ${drawerWidth}px)`,
                padding: "16px",
                marginRight: "10px",
            },
        }),
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down("md")]: {
                marginLeft: "20px",
            },
            [theme.breakpoints.down("sm")]: {
                marginLeft: "10px",
            },
        }),
    })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const { message, toaster } = useSelector((state) => state.sharedState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useRestoreScrollYPosition();

    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const handleToken = useCallback(() => {
        const token = localStorage.getItem('token');

        try {
            if (token == null) {
                navigate('/');
            }
            const userToken = jwt_decode(token);
            dispatch(setUserToken(userToken));
        }
        catch (err) {
            console.log(err);
            dispatch(showSessionTimeout());
            navigate('/');
        }

    }, [dispatch, navigate]);

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    useEffect(handleToken, [handleToken]);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened
                        ? theme.transitions.create("width")
                        : "none",
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* Toaster utility */}
            {toaster.isOpen &&
                <Snackbar
                    open={toaster.isOpen}
                    autoHideDuration={3000}
                    onClose={() => dispatch(closeToaster())}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    TransitionComponent={props => <Grow {...props} />}
                    disableWindowBlurListener={true}
                >
                    <Alert onClose={() => dispatch(closeToaster())} severity={toaster.severity} sx={{ width: '100%' }} variant="filled">
                        {toaster.format !== '' ? t(toaster.message, toaster.format) : t(toaster.message)}
                    </Alert>
                </Snackbar>
            }

            {/* Message utility */}
            {message.isOpen && (
                <SweetAlert
                    title={t(message.title)}
                    onConfirm={() => dispatch(closeMessage())}
                    onCancel={() => dispatch(closeMessage())}
                >
                    {t(message.text)}
                </SweetAlert>
            )}

            {/* drawer */}
            <Sidebar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={navigation}
                    icon
                    title
                    rightAlign
                />
                <Outlet />
            </Main>
            <Customization />
        </Box>
    );
};

export default MainLayout;
