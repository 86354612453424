import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from "react-redux";

const FAVORITES_PATH = '/main/manage-funders';
const GRANT_SECRETARY_PATH = '/main/grant-secretary';
const FOUNDATION_PATH = '/main/foundation-profile';

const useRestoreScrollYPosition = () => {

  const { pathname } = useLocation();

  const {
    fetchStatus: { funders: GSFunders, fundersCustomDocument: GSFundersCustomDocument },
  } = useSelector(state => state.grantSecretaryState);

  const {
    fetchStatus: {
      favoritesPagedList,
      favoritesPagedSharedList,
      favoriteFundersPagedList,
    }
  } = useSelector(state => state.favoritesState);

  const lastLocation = JSON.parse(localStorage.getItem('lastLocation'));

  useEffect(() => {
    if (lastLocation && lastLocation.pathname !== pathname && !pathname.includes(FOUNDATION_PATH)) {

      window.scrollTo(0, 0);
      localStorage.removeItem('lastLocation');
    }

    if (lastLocation && lastLocation.pathname === pathname) {

      window.scrollTo(0, 0);

      if (pathname === GRANT_SECRETARY_PATH && GSFunders && GSFundersCustomDocument) {
        window.scrollTo(0, lastLocation.scrollPosition);

      } else if (pathname === FAVORITES_PATH && favoriteFundersPagedList && favoritesPagedSharedList && favoritesPagedList) {
        window.scrollTo(0, lastLocation.scrollPosition);
      }
    }
  }, [pathname, GSFunders, GSFundersCustomDocument, favoriteFundersPagedList, favoritesPagedSharedList, favoritesPagedList, lastLocation]);


};

const storeLocationScrollPosition = () => {
  localStorage.setItem('lastLocation', JSON.stringify({ pathname: window.location.pathname, scrollPosition: window.scrollY }));

};


export { useRestoreScrollYPosition, storeLocationScrollPosition };