import { createSlice } from "@reduxjs/toolkit";
import { adminApi } from 'Services/AdminService';
import { countryApi } from 'Services/CountryService';

export const initialState = {
    roleList: ['Admin', 'User', 'SubUser', 'Supervisor', 'Member', 'Demo', 'Sales', 'SuperAdmin', 'IT'],
    subscriptionPlanList: [],
    countryList: [],
    selectedCountry: {
        id: null,
        code: null,
        description: null,
        provinceOrStateList: []
    },
    selectedProvinceOrState: {
        id: null,
        code: null,
        description: null,
    },
    firstName: {
        value: '',
        error: ''
    },
    lastName: {
        value: '',
        error: ''
    },
    phoneNumber: {
        value: '',
        error: ''
    },
    userName: {
        value: '',
        error: ''
    },
    email: {
        value: '',
        error: ''
    },
    planDescription: {
        value: '',
        error: ''
    },
    expirationDate: {
        value: '',
        error: ''
    },
    amountUsers: {
        value: '',
        error: ''
    },
    amountLicenses: {
        value: '',
        error: ''
    },
    userRole: {
        value: '',
        error: ''
    },
    companyName: {
        value: '',
        error: ''
    },
    businessEin: {
        value: '',
        error: ''
    },
    addressLine: {
        value: '',
        error: ''
    },
    addressLine2: {
        value: '',
        error: ''
    },
    city: {
        value: '',
        error: ''
    },
    provinceOrState: {
        value: '',
        error: ''
    },
    postalCode: {
        value: '',
        error: ''
    },
    country: {
        value: '',
        error: ''
    },
    userNameIsEmail: {
        value: false,
        error: ''
    }
};

const updateAccountSlice = createSlice({
    name: "update-account",
    initialState,
    reducers: {
        handleChange: (state, action) => {
            state[action.payload.field].error = '';
            state[action.payload.field].value = action.payload.value;            
        },
        handleModelErrors: (state, action) => {
            Object.entries(action.payload).forEach(([field, errors]) => { 
                state[field].error = errors[0];
            });
        },
        handleFillCountryAndState: (state, action) => {

            const selectedCountry = state.countryList.find(o => o.description === state.country.value);

            if (selectedCountry) {
                state.selectedCountry = selectedCountry;
                const selectedProvinceOrState = selectedCountry.provinceOrStateList.find(o => o.description === state.provinceOrState.value);
                if (selectedProvinceOrState) {
                    state.selectedProvinceOrState = selectedProvinceOrState;
                }
            }
        },
        handleChangeCountry: (state, action) => {
            state.selectedCountry = state.countryList.find(o => o.description === action.payload);
            state.country.value = state.countryList.find(o => o.description === action.payload).description;
            state.selectedProvinceOrState = initialState.selectedProvinceOrState;
            state.provinceOrState = initialState.provinceOrState;
        },
        handleChangeProvinceOrState: (state, action) => {
            if (action.payload?.value) {
                state.selectedProvinceOrState = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '');
                state.provinceOrState.value = state.selectedCountry.provinceOrStateList.find(o => o.description === action.payload.value || '').description;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            adminApi.endpoints.getUserAccount.matchFulfilled, (state, action) => {
                Object.entries(action.payload).forEach(([field, value]) => {
                    if (state[field] != null) {

                        state[field].value = value ?? '';    

                        if (field === 'userRole') {
                            const roles = value.split(',');
                            state[field].value = roles[0];
                        }
                    }
                });                 
            }
        )
        .addMatcher(
            countryApi.endpoints.getCountryList.matchFulfilled, (state, action) => {
                state.countryList = action.payload;
            }
        )
    }
});

export const { 
    handleChange,
    handleModelErrors,
    handleChangeCountry,
    handleChangeProvinceOrState,
    handleFillCountryAndState
} = updateAccountSlice.actions;

export default updateAccountSlice.reducer;