import { emptySplitApi } from './index'

export const sharedProjectApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllShared: builder.mutation({
      query: (model) => ({
        url: `api/v1/project-service/shared-project?`
          + `&pageNumber=${model?.pageNumber || 0}`
          + `&pageSize=${model?.pageSize || 10}`
          + `&searchTerm=${model?.searchTerm || ''}`
          + `&orderField=${model?.orderField || '`name`'}`
          + `&showArchived=${model?.showArchived || 'false'}`
          + `&orderDirection=${model?.orderDirection || 'asc'}`,
        method: "GET",
      }),
    }),
    getSharedProjectList: builder.query({
      query: (model) => ({
        url: `api/v1/project-service/shared-project?`
          + `&pageNumber=${model?.pageNumber || 0}`
          + `&pageSize=${model?.pageSize || 10}`
          + `&searchTerm=${model?.searchTerm || ''}`
          + `&orderField=${model?.orderField || '`name`'}`
          + `&showArchived=${model?.showArchived || 'false'}`
          + `&orderDirection=${model?.orderDirection || 'asc'}`,
        method: "GET",
      }),
    }),
    getSharedProjectListWithTrigger: builder.mutation({
      query: () => ({
        url: `api/v1/project-service/shared-project?`
          + `pageNumber=0`
          + `&pageSize=10`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSharedProjectListQuery,
  useGetSharedProjectListWithTriggerMutation,
  useGetAllSharedMutation,
} = sharedProjectApi;
