import account, { icons as accountIcons } from './account';
import administration, { icons as administrationIcons } from './administration';
import userProject, { icons as userProjectIcons } from './userProject';
import searchEngine, { icons as searchEngineIcons } from './searchEngine';
import searchEngineCanada, { icons as searchEngineCanadaIcons } from './searchEngineCanada';
import documentGenerator, { icons as documentGeneratorIcons } from './documentGenerator';
import grantSecretary, { icons as grantSecretaryIcons } from './grantSecretary';
import manageFunders, { icons as manageFundersIcons } from './manageFunders';
import manageFundersv2 from './manageFundersv2';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        administration, 
        account, 
        userProject, 
        searchEngine, 
        documentGenerator, 
        grantSecretary,
        manageFundersv2
    ]
};

export const menuIcons = {
    ...accountIcons,
    ...administrationIcons,
    ...userProjectIcons,
    ...searchEngineIcons,
    ...documentGeneratorIcons,
    ...grantSecretaryIcons,
    ...manageFundersIcons
};

export default menuItems;
