import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import OrganizationProfileInfoPage from 'views/account/organization-profile/OrganizationProfileInfo.Page';
import OrganizationAdditionalEmailPage from 'views/account/organization-profile/OrganizationAdditionalEmail.Page';
import SharedLetterHeadPage from 'views/shared/SharedLetterHead.Page';
import ProfileInfoPage from 'views/account/user-profile/ProfileInfo.Page';
import AlertNotificationPage from 'views/account/user-profile/AlertNotification.Page';
import SignaturePage from 'views/account/user-profile/SignaturePage';
import ResetPassword from 'views/account/user-profile/ResetPassword.Page';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const AdditionalUsersPage = Loadable(lazy(() => import('views/account/additional-users')));
const UserProfilePage = Loadable(lazy(() => import('views/account/user-profile')));
const ManageAccountPage = Loadable(lazy(() => import('views/admin/manage-accounts')));
const ManageAnnouncementPage =  Loadable(lazy(() => import('views/admin/manage-announcements')));
const ManageSubscriptionPlanPage =  Loadable(lazy(() => import('views/admin/manage-subscription-plans')));
const ManageCouponsPage =  Loadable(lazy(() => import('views/admin/manage-coupons')));
const ManageFeedbacksPage =  Loadable(lazy(() => import('views/admin/manage-feedbacks')));
const CreateAccountPage = Loadable(lazy(() => import('views/admin/create-account')));
const CreateAnnouncementPage = Loadable(lazy(() => import('views/admin/create-announcement')));
const UpdateAnnouncementPage = Loadable(lazy(() => import('views/admin/update-announcement')));
const CreateSubscriptionPage = Loadable(lazy(() => import('views/admin/create-subscription-plan')));
const UpdateSubscriptionPage = Loadable(lazy(() => import('views/admin/update-subscription-plan')));
const UpdateAccountPage = Loadable(lazy(() => import('views/admin/update-account')));
const ManagePlansPage = Loadable(lazy(() => import('views/admin/manage-plans')));
const OrganizationProfilePage = Loadable(lazy(() => import('views/account/organization-profile')));
const UserProjectPage = Loadable(lazy(() => import('views/user-project')));
const UserProjectArchive = Loadable(lazy(() => import('views/user-project/UserProject.Archive')));
const SearchByGivingHistoryPage = Loadable(lazy(() => import('views/search-engine/by-giving-history')));
const SearchByGivingHistoryPageV2 = Loadable(lazy(() => import('views/search-engine/giving-history-v2')));
const SearchByNamePage = Loadable(lazy(() => import('views/search-engine/by-name')));
const SearchByNamePageV2 = Loadable(lazy(() => import('views/search-engine/by-name-v2')));
const SearchByNamePageCA = Loadable(lazy(() => import('views/search-engine-ca/by-name')));
const SearchByNamePageCAV2 = Loadable(lazy(() => import('views/search-engine-ca/by-name-v2')));
const SearchByDirectorPage = Loadable(lazy(() => import('views/search-engine/by-director')));
const SearchByDirectorPageV2 = Loadable(lazy(() => import('views/search-engine/by-director-v2')));
const SearchByYearPage = Loadable(lazy(() => import('views/search-engine/by-year')));
const SearchByRatingPage = Loadable(lazy(() => import('views/search-engine/by-rating')));
const SearchByCustomPage = Loadable(lazy(() => import('views/search-engine/by-custom')));
const ProjectBudgetPage = Loadable(lazy(() => import('views/project-budget')));
const FoundationProfilePage = Loadable(lazy(() => import('views/foundation-profile')));
const FoundationProfilePageV2 = Loadable(lazy(() => import('views/foundation-profile-v2')));
const InquiryPage = Loadable(lazy(() => import('views/document-generator/inquiry')));
const ProposalPage = Loadable(lazy(() => import('views/document-generator/proposal')));
const CustomPage = Loadable(lazy(() => import('views/document-generator/custom')));
const GrantSecretary = Loadable(lazy(() => import('views/grant-secretary')));
const GrantSecretaryPreviewDocuments = Loadable(lazy(() => import('views/grant-secretary/GrantSecretary.PreviewDocuments')));
const ManageFundersPage = Loadable(lazy(() => import('views/manage-funders-v2')));
// const FavoritesPage = Loadable(lazy(() => import('views/manage-funders/favorites')));
// const BlockedFundersPage = Loadable(lazy(() => import('views/manage-funders/blocked-funders')));
const CustomDatabasesPage = Loadable(lazy(() => import('views/manage-funders-v2/custom-databases')));
const CustomDatabasesImportPage = Loadable(lazy(() => import('views/manage-funders-v2/custom-databases/CustomDatabase.Import')));
const CustomProfilePage = Loadable(lazy(() => import('views/custom-profile')));
const DocumentInquiryPage = Loadable(lazy(() => import('views/document-generator/document-inquiry')));
const DocumentInquiryFunderPage = Loadable(lazy(() => import('views/document-generator/document-inquiry/DocumentInquiry.FunderPage')));
const DocumentProposalPage = Loadable(lazy(() => import('views/document-generator/document-proposal')));
const DocumentProposalFunderPage = Loadable(lazy(() => import('views/document-generator/document-proposal/DocumentProposal.FunderPage')));
const DocumentCustomPage = Loadable(lazy(() => import('views/document-generator/document-custom')));
const DocumentCustomFunderPage = Loadable(lazy(() => import('views/document-generator/document-custom/DocumentCustom.FunderPage')));
const SearchByKeywordPage = Loadable(lazy(() => import('views/search-engine/by-keyword')));
const SearchByDirectorPageCA = Loadable(lazy(() => import('views/search-engine-ca/by-director')));
const SearchByDirectorPageCAV2 = Loadable(lazy(() => import('views/search-engine-ca/by-director-v2')));
const SearchByGivingHistoryPageCA = Loadable(lazy(() => import('views/search-engine-ca/by-giving-history')));
const SearchByGivingHistoryPageCAV2 = Loadable(lazy(() => import('views/search-engine-ca/by-giving-history-v2')));
const SearchByYearPageCA = Loadable(lazy(() => import('views/search-engine-ca/by-year'))); 
const FoundationProfileCanadian = Loadable(lazy(() => import('views/foundation-profile-ca')));
const FoundationProfileCanadianV2 = Loadable(lazy(() => import('views/foundation-profile-ca-v2')));
const SearchByInternacionalFundingPage = Loadable(lazy(() => import('views/search-engine/by-internacional-funding'))); 
const ThankYouLetterPage = Loadable(lazy(() => import('views/document-generator/thank-you'))); 
const DocumentThankYouPage = Loadable(lazy(() => import('views/document-generator/document-thank-you'))); 
const DocumentThankYouFunderPage = Loadable(lazy(() => import('views/document-generator/document-thank-you/DocumentThankYou.FunderPage'))); 
const ImpactReportLetterPage = Loadable(lazy(() => import('views/document-generator/impact-report'))); 
const DocumentImpactReportPage = Loadable(lazy(() => import('views/document-generator/document-impact-report'))); 
const DocumentImpactReportFunderPage = Loadable(lazy(() => import('views/document-generator/document-impact-report/DocumentImpactReport.FunderPage'))); 


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/admin/manage-accounts',
            element: <ManageAccountPage />
        },
        {
            path: '/admin/manage-announcements',
            element: <ManageAnnouncementPage />
        },
        {
            path: '/admin/manage-subscription-plans',
            element: <ManageSubscriptionPlanPage />
        },
        {
            path: '/admin/manage-plans',
            element: <ManagePlansPage />
        },
        {
            path: '/admin/create-account',
            element: <CreateAccountPage />
        },
        {
            path: '/admin/create-announcement',
            element: <CreateAnnouncementPage />
        },
        {
            path: '/admin/update-announcement/:id',
            element: <UpdateAnnouncementPage />
        },
        {
            path: '/admin/create-subscription-plan',
            element: <CreateSubscriptionPage />
        },
        {
            path: '/admin/manage-coupons',
            element: <ManageCouponsPage />
        },
        {
            path: '/admin/manage-feedbacks',
            element: <ManageFeedbacksPage />
        },
        {
            path: '/admin/update-subscription-plan/:subscriptionPlanId',
            element: <UpdateSubscriptionPage />
        },
        {
            path: '/admin/update-account/:userId',
            element: <UpdateAccountPage />
        },
        {
            path: '/account/user-profile',
            element: <UserProfilePage />,
            children: [
                {
                    index: true,
                    element: <ProfileInfoPage />,
                },
                {
                    path: '/profile-info',
                    element: <ProfileInfoPage />,
                },
                {
                    path: '/email-alert',
                    element: <AlertNotificationPage />,
                },
                {
                    path: '/signature',
                    element: <SignaturePage />,
                },
                {
                    path: '/letter-head',
                    element: <SharedLetterHeadPage sharedLetterHeadType="user" />,
                },
                {
                    path: "/password-reset",
                    element: <ResetPassword />
                },
            ]
        },
        {
            path: '/account/additional-users',
            element: <AdditionalUsersPage />
        },
        {
            path: '/account/organization-profile',
            element: <OrganizationProfilePage />,
            children: [
                {
                    index: true,
                    element: <OrganizationProfileInfoPage />,
                },
                {
                    path: '/profile-info',
                    element: <OrganizationProfileInfoPage />,
                },
                {
                    path: '/additional-email',
                    element: <OrganizationAdditionalEmailPage />,
                },
                {
                    path: '/letter-head',
                    element: <SharedLetterHeadPage sharedLetterHeadType="organization"  />,
                }
            ]
        },
        {
            path: '/user-project/list',
            element: <UserProjectPage />
        },
        {
            path: '/user-project/archive',
            element: <UserProjectArchive />
        },
        {
            path: '/search/by-giving-history',
            element: <SearchByGivingHistoryPage />
        },
        {
            path: '/search/giving-history-v2',
            element: <SearchByGivingHistoryPageV2 />
        },
        {
            path: '/search/by-name',
            element: <SearchByNamePage />
        },
        {
            path: '/search/by-name-v2',
            element: <SearchByNamePageV2 />
        },
        {
            path: '/search/by-director',
            element: <SearchByDirectorPage />
        },
        {
            path: '/search/by-director-v2',
            element: <SearchByDirectorPageV2 />
        },
        {
            path: '/search/by-year',
            element: <SearchByYearPage />
        },
        {
            path: '/search/by-rating',
            element: <SearchByRatingPage />
        },
        {
            path: '/search/by-custom',
            element: <SearchByCustomPage />
        },
        {
            path: '/project-budget/:userProjectId',
            element: <ProjectBudgetPage />
        },
        {
            path: '/foundation-profile/:profileId',
            element: <FoundationProfilePage />
        },
        {
            path: '/foundation-profile-v2/:profileId',
            element: <FoundationProfilePageV2 />
        },
        {
            path: '/document-generator/inquiry',
            element: <InquiryPage />
        },
        {
            path: '/document-generator/proposal',
            element: <ProposalPage />
        },
        {
            path: '/document-generator/custom-letter',
            element: <CustomPage />
        },
        {
            path: '/grant-secretary',
            element: <GrantSecretary />
        },
        {
            path: '/grant-secretary/preview-documents',
            element: <GrantSecretaryPreviewDocuments />
        },
        {
            path: '/manage-funders',
            element: <ManageFundersPage />
           
        },
        // {
        //     path: '/manage-funders/favorites',
        //     element: <FavoritesPage />
        // },
        // {
        //     path: '/manage-funders/blocked-funders',
        //     element: <BlockedFundersPage />
        // },
        {
            path: '/manage-funders/custom-databases',
            element: <CustomDatabasesPage />
        },
        {
            path: '/manage-funders/custom-databases/import',
            element: <CustomDatabasesImportPage />
        },
        {
            path: '/custom-profile/:customItemId',
            element: <CustomProfilePage />
        },
        {
            path: '/document-generator/document-inquiry',
            element: <DocumentInquiryPage />
        },
        {
            path: '/document-generator/document-inquiry/funder/:profileId',
            element: <DocumentInquiryFunderPage />
        },
        {
            path: '/document-generator/document-proposal',
            element: <DocumentProposalPage />
        },
        {
            path: '/document-generator/document-proposal/funder/:profileId',
            element: <DocumentProposalFunderPage />
        },
        {
            path: '/document-generator/document-custom',
            element: <DocumentCustomPage />
        },
        {
            path: '/document-generator/document-custom/funder/:profileId',
            element: <DocumentCustomFunderPage />
        },
        {
            path: '/search/by-keyword',
            element: <SearchByKeywordPage />
        },
        {
            path: '/search/name-ca',
            element: <SearchByNamePageCA />
        },
        {
            path: '/search/name-ca-v2',
            element: <SearchByNamePageCAV2 />
        },
        {
            path: '/search/directors-ca',
            element: <SearchByDirectorPageCA />
        },
        {
            path: '/search/directors-ca-v2',
            element: <SearchByDirectorPageCAV2 />
        },
        {
            path: '/search/giving-history-ca',
            element: <SearchByGivingHistoryPageCA />
        },
        {
            path: '/search/giving-history-ca-v2',
            element: <SearchByGivingHistoryPageCAV2 />
        },
        {
            path: '/search/year-ca',
            element: <SearchByYearPageCA />
        },
        {
            path: '/search/by-international-funding',
            element: <SearchByInternacionalFundingPage />
        },
        {
            path: '/foundation-profile-ca/:profileId',
            element: <FoundationProfileCanadian />
        },
        {
            path: '/foundation-profile-ca-v2/:profileId',
            element: <FoundationProfileCanadianV2 />
        },
        {
            path: '/document-generator/thank-you',
            element: <ThankYouLetterPage />
        },
        {
            path: '/document-generator/document-thank-you',
            element: <DocumentThankYouPage />
        },
        {
            path: '/document-generator/document-thank-you/funder/:profileId',
            element: <DocumentThankYouFunderPage />
        },
        {
            path: '/document-generator/impact-report',
            element: <ImpactReportLetterPage />
        },
        {
            path: '/document-generator/document-impact-report',
            element: <DocumentImpactReportPage />
        },
        {
            path: '/document-generator/document-impact-report/funder/:profileId',
            element: <DocumentImpactReportFunderPage />
        }
    ]
};

export default MainRoutes;
